export const state = () => ({
  user: null,
});

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
}

const actions = {
  login({dispatch}, payload) {
    return new Promise(async (resolve, reject) => {
      dispatch('resetUser');

      this.$auth.loginWith('local', {
        data: payload
      }).then((response) => {
        console.log(response.data.responseValue);
        let user = response.data.responseValue;
        dispatch('setNewUser', user);
        resolve(user);
      }).catch((error) => {
        reject(error.response.data)
      });
    });
  },
  signup(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/register', payload).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error.response.data)
      });
    });
  },
  setNewUser({commit}, user) {
    commit('setUser', user);
    this.$auth.setUser(user);
    this.$auth.setUserToken(user.access_token);
    this.$auth.$storage.setUniversal('user', JSON.stringify(user))
    this.$axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
  },
  resetUser({commit}) {
    commit('setUser', null);
    this.$auth.$storage.removeUniversal('user')
  },
  logout() {
    console.log('here');
    console.log(this.$auth)
    this.$auth.setUser();
    this.$auth.setUserToken();
    this.$auth.$state.loggedIn = false
  },
  getWKNDetails({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get('get-item-datas?code=' + payload.slug).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error.response.data)
      });
    });
  },
}

export default {
  state,
  mutations,
  actions
}
