import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5081faa5 = () => interopDefault(import('../pages/commodities.vue' /* webpackChunkName: "pages/commodities" */))
const _bea4f376 = () => interopDefault(import('../pages/currencies.vue' /* webpackChunkName: "pages/currencies" */))
const _2ed57966 = () => interopDefault(import('../pages/derivatives.vue' /* webpackChunkName: "pages/derivatives" */))
const _0c0c8571 = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _e313b0dc = () => interopDefault(import('../pages/etfs.vue' /* webpackChunkName: "pages/etfs" */))
const _1e080214 = () => interopDefault(import('../pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _61702b98 = () => interopDefault(import('../pages/indizes.vue' /* webpackChunkName: "pages/indizes" */))
const _a095577c = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _6e02e403 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _17f2747a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _3e95dc0e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _343a0c1a = () => interopDefault(import('../pages/stocks.vue' /* webpackChunkName: "pages/stocks" */))
const _b7497e28 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0dbbdb27 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _7d726d02 = () => interopDefault(import('../pages/_name/_slug/index.vue' /* webpackChunkName: "pages/_name/_slug/index" */))
const _7d3c674f = () => interopDefault(import('../pages/_name/_slug/index_backup.vue' /* webpackChunkName: "pages/_name/_slug/index_backup" */))
const _4ef37bb6 = () => interopDefault(import('../pages/_name/_typ/_wkn/index.vue' /* webpackChunkName: "pages/_name/_typ/_wkn/index" */))
const _1d165cfb = () => interopDefault(import('../pages/_typ/_uisin/_wkn/index.vue' /* webpackChunkName: "pages/_typ/_uisin/_wkn/index" */))
const _4d412689 = () => interopDefault(import('../pages/_assets/_name/_typ/_wkn/index.vue' /* webpackChunkName: "pages/_assets/_name/_typ/_wkn/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/commodities",
    component: _5081faa5,
    name: "commodities"
  }, {
    path: "/currencies",
    component: _bea4f376,
    name: "currencies"
  }, {
    path: "/derivatives",
    component: _2ed57966,
    name: "derivatives"
  }, {
    path: "/disclaimer",
    component: _0c0c8571,
    name: "disclaimer"
  }, {
    path: "/etfs",
    component: _e313b0dc,
    name: "etfs"
  }, {
    path: "/forgotpassword",
    component: _1e080214,
    name: "forgotpassword"
  }, {
    path: "/indizes",
    component: _61702b98,
    name: "indizes"
  }, {
    path: "/legal-notice",
    component: _a095577c,
    name: "legal-notice"
  }, {
    path: "/login",
    component: _6e02e403,
    name: "login"
  }, {
    path: "/profile",
    component: _17f2747a,
    name: "profile"
  }, {
    path: "/register",
    component: _3e95dc0e,
    name: "register"
  }, {
    path: "/stocks",
    component: _343a0c1a,
    name: "stocks"
  }, {
    path: "/",
    component: _b7497e28,
    name: "index"
  }, {
    path: "/:slug",
    component: _0dbbdb27,
    name: "slug"
  }, {
    path: "/:name/:slug",
    component: _7d726d02,
    name: "name-slug"
  }, {
    path: "/:name/:slug?/index_backup",
    component: _7d3c674f,
    name: "name-slug-index_backup"
  }, {
    path: "/:name/:typ?/:wkn",
    component: _4ef37bb6,
    name: "name-typ-wkn"
  }, {
    path: "/:typ/:uisin?/:wkn",
    component: _1d165cfb,
    name: "typ-uisin-wkn"
  }, {
    path: "/:assets/:name?/:typ?/:wkn",
    component: _4d412689,
    name: "assets-name-typ-wkn"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
