import 'cookieconsent/build/cookieconsent.min.css';
import 'cookieconsent/build/cookieconsent.min';

export default () => {
    window.cookieconsent.initialise({
        container: null,
        autoOpen: true,
        position: 'bottom-left',
        //type: 'opt-in',
        palette: {
            popup: { background: "#fff" },
            button: { background: "#007bff", color:'#ffffff' },
        },
        revokable: true,
        onInitialise: function(status) {
            console.log("cookie_content onInitialise");
        },
        onStatusChange: function(status) {
            //console.log(this.hasConsented() ? 'enable cookies' : 'disable cookies');
        },
        law: { regionalLaw: false, },
        location: false,
    });

    //console.log("cookie_content 1", window.cookieconsent);
    setTimeout(()=>{
        const revokeButton = document.querySelector('.cc-revoke');
        //console.log("cookie_content revokeButton",revokeButton);
        if (revokeButton) {
            revokeButton.style.setProperty('display', 'block');
            revokeButton.classList.add('cc-active');
        }
        //console.log("cookie_content revokeButton",revokeButton);
    },1000);

};
