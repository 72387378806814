export default function ({app}, inject) {
    const dir = () => app.i18n.locales.find((x) => x.code === app.i18n.locale)?.dir;
    inject('dir', dir);

    const t2 = (key, defaultKey) => {
        const translation = app.i18n.t(key)
        if (translation === key) {
            return app.i18n.t(defaultKey)
        }
        return translation
    }
    inject('t2', t2)
}