import Vue from 'vue';

import {
  BOverlay,
  BTable,
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BModal,
  BFormSelect,
  BFormGroup,
  BIcon,
  BFormCheckbox,
  BInputGroup,
  BInputGroupText,
  BBadge
} from 'bootstrap-vue';

Vue.component('BOverlay', BOverlay);
Vue.component('BTable', BTable);
Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BFormInput', BFormInput);
Vue.component('BButton', BButton);
Vue.component('BModal', BModal);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BIcon', BIcon);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupText', BInputGroupText);
Vue.component('BBadge', BBadge);
